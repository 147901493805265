var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "p-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add Document",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)], 1)], 1), _c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "custom_table_synchronization",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-info btn-sm",
          on: {
            "click": function click($event) {
              return _vm.synchronizeItem(props.row.ID);
            }
          }
        }, [!props.row.buttons.sync.isLoading ? _c('font-awesome-icon', {
          attrs: {
            "icon": "cloud-download-alt"
          }
        }) : _vm._e(), props.row.buttons.sync.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e()], 1), !props.row.Enabled ? _c('span', {
          staticClass: "btn btn-secondary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.enableItem(props.row);
            }
          }
        }, [!props.row.buttons.enable.isLoading ? _c('font-awesome-icon', {
          attrs: {
            "icon": "eye-slash"
          }
        }) : _vm._e(), props.row.buttons.enable.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e()], 1) : _vm._e(), props.row.Enabled ? _c('span', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.disableItem(props.row);
            }
          }
        }, [!props.row.buttons.disable.isLoading ? _c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        }) : _vm._e(), props.row.buttons.disable.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e()], 1) : _vm._e(), _c('span', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)])]);
      }
    }])
  })], 1)], 1), _c('b-modal', {
    ref: "modal-form",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.modalForm.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "id": "input-group-1",
      "label": "Source document title",
      "label-for": "input-1",
      "description": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "Enter title"
    },
    model: {
      value: _vm.modalForm.sourceDocument.title,
      callback: function callback($$v) {
        _vm.$set(_vm.modalForm.sourceDocument, "title", $$v);
      },
      expression: "modalForm.sourceDocument.title"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "id": "input-group-2",
      "label": "Source document url",
      "label-for": "input-2",
      "description": "Document must be available by the link without authorization"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "Enter url"
    },
    model: {
      value: _vm.modalForm.sourceDocument.url,
      callback: function callback($$v) {
        _vm.$set(_vm.modalForm.sourceDocument, "url", $$v);
      },
      expression: "modalForm.sourceDocument.url"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "id": "input-group-3",
      "label": "Destination document",
      "label-for": "input-3",
      "description": "Must be created via Joomla interface before linking"
    }
  }, [_c('multiselect', {
    attrs: {
      "id": "ms-joomla-docs",
      "disabled": _vm.modalForm.destinationDocument.disabled,
      "track-by": "id",
      "label": "label",
      "options": _vm.modalForm.destinationDocument.options,
      "show-labels": false,
      "placeholder": "",
      "multiple": false,
      "allow-empty": false
    },
    model: {
      value: _vm.modalForm.destinationDocument.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.modalForm.destinationDocument, "selected", $$v);
      },
      expression: "modalForm.destinationDocument.selected"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.saveModal();
      }
    }
  }, [_vm.modalForm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.modalForm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "save"
    }
  }) : _vm._e(), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }