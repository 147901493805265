<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="p-3">
        <b-row>
          <b-col lg="2">
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Document"
                size="sm"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <table-custom
          ref="dataTable"
          :name="`custom_table_synchronization`"
          :loading="dataTable.isLoading"
          :data="dataTable.dataSet"
          :options="dataTable.options"
        >
          <div slot="custom-actions" slot-scope="props">
            <div class="btn-group">
              <span
                class="btn btn-info btn-sm"
                @click="synchronizeItem(props.row.ID)"
              >
                <font-awesome-icon
                  v-if="!props.row.buttons.sync.isLoading"
                  icon="cloud-download-alt"
                />
                <b-spinner
                  v-if="props.row.buttons.sync.isLoading"
                  small
                  type="grow"
                />
              </span>

              <span
                v-if="!props.row.Enabled"
                class="btn btn-secondary btn-sm"
                @click="enableItem(props.row)"
              >
                <font-awesome-icon
                  v-if="!props.row.buttons.enable.isLoading"
                  icon="eye-slash"
                />
                <b-spinner
                  v-if="props.row.buttons.enable.isLoading"
                  small
                  type="grow"
                />
              </span>
              <span
                v-if="props.row.Enabled"
                class="btn btn-primary btn-sm"
                @click="disableItem(props.row)"
              >
                <font-awesome-icon
                  v-if="!props.row.buttons.disable.isLoading"
                  icon="eye"
                />
                <b-spinner
                  v-if="props.row.buttons.disable.isLoading"
                  small
                  type="grow"
                />
              </span>

              <span class="btn btn-success btn-sm" @click="editItem(props.row)">
                <font-awesome-icon icon="pencil-alt" />
              </span>
              <span
                class="btn btn-danger btn-sm"
                @click="deleteItem(props.row.ID)"
              >
                <font-awesome-icon icon="trash" />
              </span>
            </div>
          </div>
        </table-custom>
      </b-container>
    </b-card>

    <b-modal
      ref="modal-form"
      centered
      hide-footer
      size="lg"
      :title="modalForm.title"
    >
      <b-card>
        <b-card-body>
          <b-form>
            <b-form-group
              id="input-group-1"
              label="Source document title"
              label-for="input-1"
              description
            >
              <b-form-input
                id="input-1"
                v-model="modalForm.sourceDocument.title"
                type="text"
                required
                placeholder="Enter title"
              />
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Source document url"
              label-for="input-2"
              description="Document must be available by the link without authorization"
            >
              <b-form-input
                id="input-1"
                v-model="modalForm.sourceDocument.url"
                type="text"
                required
                placeholder="Enter url"
              />
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label="Destination document"
              label-for="input-3"
              description="Must be created via Joomla interface before linking"
            >
              <multiselect
                id="ms-joomla-docs"
                :disabled="modalForm.destinationDocument.disabled"
                track-by="id"
                label="label"
                v-model="modalForm.destinationDocument.selected"
                :options="modalForm.destinationDocument.options"
                :show-labels="false"
                placeholder
                :multiple="false"
                :allow-empty="false"
              />
            </b-form-group>
          </b-form>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="saveModal()">
          <b-spinner v-if="modalForm.isLoading" small type="grow" />
          <font-awesome-icon
            v-if="!modalForm.isLoading"
            icon="save"
          />&nbsp;Save
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" />&nbsp;Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import TableCustom from "@/components/TableCustom";

import { FORM_MODE } from "@/shared/constants";

import Vue from "vue";

export default {
  name: "GoogleDocSyncronizer",
  components: {
    TableCustom,
    Multiselect
  },
  data: function() {
    return {
      FORM_MODE: FORM_MODE,
      rawData: {},
      modalForm: {
        isLoading: false,
        sourceDocument: {
          id: "",
          title: "",
          url: ""
        },
        destinationDocument: {
          disabled: false,
          selected: {},
          options: []
        }
      },
      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Document",
            "Linked to",
            "Status",
            "Size",
            "Last check",
            "Next check",
            "Frequency",
            "Joomla updated",
            "Created by",
            "Actions"
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: true,
          showCustomActions: true,

          formatColumns: [
            {
              name: "Document",
              url: true
            },
            {
              name: "Linked to",
              url: true
            }
          ]
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.onModalShow(bvEvent, modalId);
    });

    this.dataTable.isLoading = true;

    this.getData();

    window.setInterval(() => {
      this.getData();
    }, 30000);
  },
  methods: {
    async getData() {
      let self = this;

      let response = [];

      try {
        response = await this.$api.get("knowledge-base/google-documents");

        self.dataTable.isLoading = false;

        if (response.length === 0) return;

        response.forEach(i => {
          i.Enabled = i.Enabled == "1";
          i.buttons = {
            enable: {
              isLoading: false
            },
            disable: {
              isLoading: false
            },
            sync: {
              isLoading: false
            }
          };

          i.Document = {
            label: i.Document,
            url: i.Url
          };

          i["Linked to"] = {
            label: i["Linked to"],
            url:
              "https://internal.koval-distillery.com/index.php?option=com_content&view=article&id=" +
              i.joomla_intern_content_id
          };
        });

        self.dataTable.dataSet = response;
      } catch (err) {
        self.dataTable.isLoading = false;

        self.$form.msgBoxOk(err.message);
      }
    },
    closeModal() {
      this.modalForm.mode = undefined;
      this.$refs["modal-form"].hide();
    },
    async saveModal() {
      if (!this.modalForm.sourceDocument.title) {
        await this.$form.msgBoxOk("Please enter source document title");
        return false;
      }

      if (!this.modalForm.sourceDocument.url) {
        await this.$form.msgBoxOk("Please enter source document url");
        return false;
      }

      if (!this.modalForm.destinationDocument.selected.id) {
        await this.$form.msgBoxOk("Please select destination document");
        return false;
      }
      let payload = {
        gdoc_title: this.modalForm.sourceDocument.title,
        gdoc_url: this.modalForm.sourceDocument.url,
        joomla_intern_content_id: this.modalForm.destinationDocument.selected.id
      };

      this.modalForm.isLoading = true;

      let method = "",
        url = "";

      if (this.modalForm.mode === FORM_MODE.CREATE) {
        method = "post";
        url = "knowledge-base/google-documents";
      }
      if (this.modalForm.mode === FORM_MODE.EDIT) {
        method = "put";
        url = `knowledge-base/google-documents/${this.modalForm.sourceDocument.id}`;
      }

      this.$api[method](url, payload)
        .then(() => {
          this.modalForm.isLoading = false;

          this.modalForm.mode = undefined;

          this.$refs["modal-form"].hide();

          this.getData();
        })
        .catch(response => {
          this.modalForm.isLoading = false;

          self.$form.makeToastError(response.message);
        });
    },
    onModalShow() {
      let self = this;

      self.modalForm.destinationDocument.disabled = true;

      this.$api
        .get("knowledge-base/joomla-documents")
        .then(response => {
          self.modalForm.destinationDocument.options = response.map(i => ({
            id: i.id,
            label: `${i.title} [Category: ${i.category}, Created: ${i.created}]`
          }));

          //get already used documents
          let linkedId = this.dataTable.dataSet.map(
            i => i.joomla_intern_content_id
          );

          //and disable them
          self.modalForm.destinationDocument.options.forEach(i => {
            i.$isDisabled = linkedId.includes(i.id);
          });

          self.modalForm.destinationDocument.disabled = false;

          return true;
        })
        .catch(response => {
          self.$form.makeToastError(response.message);
        });
    },
    addItem: function() {
      this.modalForm.mode = FORM_MODE.CREATE;
      this.modalForm.sourceDocument.id = "";
      this.modalForm.sourceDocument.title = "";
      this.modalForm.sourceDocument.url = "";
      this.modalForm.destinationDocument.selected = {};

      this.$refs["modal-form"].show();
    },
    editItem: function(item) {
      this.modalForm.mode = FORM_MODE.EDIT;

      this.modalForm.sourceDocument.id = item["ID"];
      this.modalForm.sourceDocument.title = item["Document"].label;
      this.modalForm.sourceDocument.url = item["Url"];
      this.modalForm.destinationDocument.selected = this.modalForm.destinationDocument.options.find(
        d => d.id === item["joomla_intern_content_id"]
      );

      this.$refs["modal-form"].show();
    },
    async deleteItem(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        "Synchronization record will be deleted. Latest document snapshot will remain in the knowledge base. Do you want to proceed?"
      );

      if (!confirm) return;

      let self = this;

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        item => item.ID !== id
      );

      this.$api
        .delete(`knowledge-base/google-documents/${item.ID}`)
        .then(response => {
          this.getData();
          self.$form.makeToastInfo(response.message);
        })
        .catch(response => {
          self.$form.makeToastError(response.message);
        });
    },
    async disableItem(item) {
      //let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        "Synchronization will be suspended. Do you want to proceed?"
      );

      if (!confirm) return;

      let self = this;

      self.setTableItemButtonLoading(item.ID, "disable", true);

      this.$api
        .put(`knowledge-base/google-documents/${item.ID}`, {
          enabled: 0
        })
        .then(() => {
          self.setTableItemButtonLoading(item.ID, "disable", false);

          this.getData();

          // self.$form.makeToastInfo(response.message);
        })
        .catch(response => {
          self.setTableItemButtonLoading(item.ID, "disable", false);

          self.$form.makeToastError(response.message);
        });
    },
    async enableItem(item) {
      //let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        "Synchronization will be resumed. Do you want to proceed?"
      );

      if (!confirm) return;

      let self = this;

      self.setTableItemButtonLoading(item.ID, "enable", true);

      this.$api
        .put(`knowledge-base/google-documents/${item.ID}`, {
          enabled: 1
        })
        .then(() => {
          self.setTableItemButtonLoading(item.ID, "enable", false);

          this.getData();

          // self.$form.makeToastInfo(response.message);
        })
        .catch(response => {
          self.setTableItemButtonLoading(item.ID, "enable", false);

          self.$form.makeToastError(response.message);
        });
    },
    setTableItemButtonLoading(id, buttonName, status) {
      let itemIdx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let item = this.dataTable.dataSet[itemIdx];

      item.buttons[buttonName].isLoading = status;

      Vue.set(this.dataTable.dataSet, itemIdx, item);
    },
    async synchronizeItem(id) {
      let confirm = await this.$form.showConfirmation(
        "Document will be updated in a minute. Do you want to proceed?"
      );

      if (!confirm) return;

      let self = this;

      this.setTableItemButtonLoading(id, "sync", true);

      this.$api
        .get(`knowledge-base/google-documents/${id}/synchronize`)
        .then(() => {
          self.setTableItemButtonLoading(id, "sync", false);

          self.getData();

          //self.$form.makeToastInfo(response.message);
        })
        .catch(response => {
          self.setTableItemButtonLoading(id, "sync", false);

          self.$form.makeToastError(response.message);
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
.modal {
  padding-right: 0px !important;
}
</style>
